import { css } from '@emotion/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { smUp, xlUp, xxlUp } from '../styles/breakpoints';
import Fade from './Fade';
import Container from './layout/Container';

const styles = css`
	position: relative;
	margin-bottom: 50px;
	max-height: calc(100vh - 100px);
	display: flex;
	flex-direction: column;
	> * {
		height: 100%;
	}
	.content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		padding: 20px 0;
		> * {
			width: 100%;
		}
	}
	@media ${smUp} {
		margin-bottom: 100px;
		.content {
			padding: 30px 0;
		}
	}
	@media ${xlUp} {
		max-height: calc(100vh - 200px);
	}
	@media ${xlUp} and (max-height: 47.98em) {
		max-height: calc(100vh - 100px);
	}
	@media ${xxlUp} {
		max-height: 100vh;
	}
`;

const HeroImage = ({ children, data, imageSrc }) => (
	<div css={styles}>
		<Fade>
			<StaticQuery
				query={graphql`
						query {
							allImageSharp {
								edges {
									node {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
							}
						}
					`}
				render={data => (
					<Img
						fluid={data.allImageSharp.edges.find(
							image => image.node.fluid.src.split('/').pop() === imageSrc,
						).node.fluid}
						imgStyle={{
							objectPosition: '50% 0%',
						}}
					/>
				)}
			/>

			<div className="content">
				<Container>
					{children}
				</Container>
			</div>
		</Fade>
	</div>
);

export default HeroImage;
