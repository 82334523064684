import { css } from '@emotion/core';
import React from 'react';
import COLORS from '../styles/colors';

const styles = css`
	font-size: 30px;
	line-height: ${40 / 30};
	font-weight: bold;
	color: ${COLORS.black};
`;

const TextHighlighted = ({ children }) => (
	<span css={styles}>
		{children}
	</span>
);

export default TextHighlighted;
