import { css } from '@emotion/core';
import React from 'react';
import { xsUp } from '../styles/breakpoints';
import COLORS from '../styles/colors';

const styles = css`
	line-height: 30px;
	strong {
		color: ${COLORS.black};
	}
	@media ${xsUp} {
		font-size: 18px;
	}
`;

const ContactItem = ({ label, value, type }) => {
	const ValueProps = {};
	let ValueTag = 'span';

	switch (type) {
		case 'phone':
			ValueTag = 'a';
			ValueProps.href = `tel:${value.replace(/ /g, '')}`;
			break;
		case 'email':
			ValueTag = 'a';
			ValueProps.href = `mailto:${value}`;
			break;
		default:
			break;
	}

	return (
		<span css={styles}>
			<strong>{label}:</strong> <ValueTag {...ValueProps}>{value}</ValueTag>
		</span>
	);
};

export default ContactItem;
