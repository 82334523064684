import React from 'react';
import { css } from '@emotion/core';
import COLORS from '../styles/colors';

const styles = css`
	color: ${COLORS.primary};
`;

const TextRed = ({ children }) => (
	<span css={styles}>
		{children}
	</span>
);

export default TextRed;
