import { css } from '@emotion/core';
import React from 'react';
import COLORS from '../styles/colors';

const styles = css`
	.list {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.item {
		padding: 10px 0;
		border-bottom: 1px solid ${COLORS.grayLight};
	}
`;

const ContactsList = ({ children }) => (
	<div css={styles}>
		<ul className="list">
			{React.Children.map(children, child => (
				<li className="item">{child}</li>
			))}
		</ul>
	</div>
);

export default ContactsList;
