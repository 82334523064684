import { Link } from 'gatsby';
import React from 'react';
import ContactItem from '../components/ContactItem';
import ContactsList from '../components/ContactsList';
import Fade from '../components/Fade';
import HeroImage from '../components/HeroImage';
import HomepageIntro from '../components/HomepageIntro';
import HomepageIntroLeft from '../components/HomepageIntroLeft';
import HomepageIntroRight from '../components/HomepageIntroRight';
import ImageItem from '../components/ImageItem';
import Container from '../components/layout/Container';
import MainTitle from '../components/MainTitle';
import ScrollTo from '../components/ScrollTo';
import Meta from '../components/seo/Meta';
import SrOnlyTitle from '../components/seo/SrOnlyTitle';
import TextHighlighted from '../components/TextHighlighted';
import TextRed from '../components/TextRed';
import MainLayout from '../layouts/MainLayout';


const Index = () => (
	<MainLayout>
		<Meta />

		<SrOnlyTitle>Uctivá poklona, jmenuji se Ondřej Palát.</SrOnlyTitle>

		<HeroImage imageSrc="hero-homepage.jpg">
			<ScrollTo to="content" />
		</HeroImage>

		<Container id="content">
			<Fade bottom>
				<HomepageIntro>
					<HomepageIntroLeft>
						<MainTitle>
							Uctivá poklona, jmenuji se <TextRed>Ondřej Palát.</TextRed>
						</MainTitle>

						<ContactsList>
							<ContactItem label="Zavolejte mi" value="+420 608 94 17 17" type="phone" />
							<ContactItem label="Napište mi" value="office@palatlegal.cz" type="email" />
							<ContactItem label="Kancelář" value="Praha, Konviktská 291/24" />
						</ContactsList>
					</HomepageIntroLeft>

					<HomepageIntroRight>
						<p>
							<TextHighlighted>
								Podnikejte, vymýšlejte a&nbsp;tvořte! Starost o&nbsp;vaše právní záležitosti už nechte na&nbsp;mně.
							</TextHighlighted>
						</p>
						<p>
							Mám rád, když je vše černé na&nbsp;bílém, i&nbsp;když svět není černobílý. Specializuji se na&nbsp;ucelenou právní podporu rodinných společností a zapojeným osobám, a&nbsp;svým klientům nabízím hlavně pevnou půdu pod&nbsp;nohama.
						</p>
						<p>
							Dokážu se postarat o&nbsp;právní podporu celé vaší společnosti i zapojeným jednotlivcům. Vyznávám tradiční&nbsp;profesní ctnosti - spolehlivost a&nbsp;profesionální úcta k&nbsp;řemeslu. Rád si vezmu na&nbsp;starost ochranu vašich hodnot i vašeho podnikání.
						</p>
					</HomepageIntroRight>
				</HomepageIntro>
			</Fade>

			<ImageItem imageSrc="homepage-01.jpg">
				<h2>Právní podpora rodinných firem je moje silná stránka.</h2>
				<p>
					<Link to="/me-advokatni-sluzby/">Co vše pro&nbsp;vás mohu udělat?</Link>
				</p>
			</ImageItem>

			<ImageItem imageSrc="homepage-02.jpg" imageRight>
				<h2>Pomáhám svým klientům v&nbsp;rozvoji a&nbsp;růstu.</h2>
				<p>
					<Link to="/kdo-jsem/">Více v&nbsp;rozhovoru</Link>
				</p>
			</ImageItem>

			{/* <ImageItem imageSrc="homepage-03.jpg">
					<h2>Píšu blog o&nbsp;svých zkušenostech a&nbsp;důležitých věcech.</h2>
					<p>
						<Link to="/blog/">O&nbsp;čem zrovna píšu</Link>
					</p>
				</ImageItem> */}
		</Container>
	</MainLayout>
);

Index.propTypes = {};

export default Index;
