import { css } from '@emotion/core';
import React from 'react';
import { smDown, smUp } from '../styles/breakpoints';

const styles = css`
	@media ${smDown} {
		margin-top: 50px;
	}
	@media ${smUp} {
		font-size: 18px;
		flex: 0 1 460px;
	}
`;

const HomepageIntroRight = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

export default HomepageIntroRight;
