import { css } from '@emotion/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Fade from './Fade';
import { smDown, smUp } from '../styles/breakpoints';
import COLORS from '../styles/colors';

const styles = ({ imageRight }) => css`
	margin-bottom: 50px;
	.content {
		a {
			color: ${COLORS.primary};
			text-decoration: none;
			&:hover {
				color: ${COLORS.primaryDark};
				text-decoration: underline;
			}
		}
		> :last-child {
			margin-bottom: 0;
		}
	}
	@media ${smDown} {
		.image {
			margin-bottom: 20px;
		}
	}
	@media ${smUp} {
		display: flex;
		align-items: center;
		margin-bottom: 100px;
		.image {
			max-width: 540px;
			flex: 1 1 540px;
			order: ${imageRight ? 1 : 0};
		}
		.content {
			padding: 0 ${imageRight ? '100px' : 0} 0 ${imageRight ? 0 : '100px'};
			flex: 1 0 400px;
		}
	}
`;

const ImageItem = ({
	imageSrc, children, data, imageRight,
}) => (
	<div css={styles({ imageRight })}>
		<div className="image">
			<Fade left={!imageRight} right={imageRight}>
				<StaticQuery
					query={graphql`
							query {
								allImageSharp {
									edges {
										node {
											fluid(maxWidth: 540, quality: 100) {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
								}
							}
						`}
					render={data => (
						<Img fluid={data.allImageSharp.edges.find(
							image => image.node.fluid.src.split('/').pop() === imageSrc,
						).node.fluid}
						/>
					)}
				/>
			</Fade>
		</div>
		<div className="content">
			<Fade left={imageRight} right={!imageRight}>
				{children}
			</Fade>
		</div>
	</div>
);

export default ImageItem;
