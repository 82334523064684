import { css } from '@emotion/core';
import React from 'react';
import { smUp } from '../styles/breakpoints';

const styles = css`
	margin-bottom: 50px;
	@media ${smUp} {
		display: flex;
		justify-content: space-between;
		margin-bottom: 100px;
	}
`;

const HomepageIntro = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

export default HomepageIntro;
