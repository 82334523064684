import { css } from '@emotion/core';
import React from 'react';
import { smUp } from '../styles/breakpoints';

const styles = css`
	@media ${smUp} {
		flex: 0 1 380px;
		margin-right: 40px;
	}
`;

const HomepageIntroLeft = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

export default HomepageIntroLeft;
